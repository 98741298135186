import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import Muuri from 'muuri';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from '../lib/swiper-bundle.min';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winW = $(window).width();
var winH = $(window).height();
var body = $('body');

const winsize = () => {
	winW = $(window).width();
	winH = $(window).height();
	const vw = winW;
	document.documentElement.style.setProperty('--vw', vw+'px');
	const vh = winH;
	document.documentElement.style.setProperty('--vh', vh+'px');
}
window.addEventListener('resize', function () {
	winsize();
});


function nav_show(){
	body.addClass('navopen');
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}


let EL_scrollTrigger;
function elFunc(EL){
	gsap.utils.toArray(EL).forEach(target => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(target.classList.contains('nosp')){
				return;
			}
		}
		if(!target.classList.contains('nomove')){
			if(target.classList.contains('elp')){
				var elc = target.querySelectorAll('.elc');
				elc.forEach((elc, i) => {
					elc.style.opacity = 0;
				});
			}else{
				target.style.opacity = 0;
			}
		}
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top bottom",
			once: true,
			onEnter: self => {
				elAnime(target);
			},
		});

		const navh = document.getElementById('nav').clientHeight;
		const up_scrollTrigger = ScrollTrigger.create({
			trigger: 'body',
			start: "top top",
			onUpdate: self => {
				const _slfscrl = self.scroll();
				if(self.direction == -1){
					document.body.classList.remove('scrldown');
				}else{
					if(_slfscrl > navh){
						if(self.prevSclrl < _slfscrl-1){
							document.body.classList.add('scrldown');
						}
						self.prevSclrl = _slfscrl;
					}
				}
				if(self.progress > 0){
					document.getElementById('btt').classList.add('show');
				}else{
					document.getElementById('btt').classList.remove('show');
				}			
			},
		});		
	});
}

function elAnime(target){
	if(target.classList.contains('elp')){
		var elc = target.querySelectorAll('.elc');
	}else{
		var elc = [target];
	}
	elc.forEach((elc, i) => {
		let _y = 120;
		let _opa = 0;
		if(elc.classList.contains('nomove')){
			_y = 0;
			_opa = 1;
		}
		gsap.fromTo(elc,{y:_y,opacity:_opa},{
			y: 0,
			opacity:1,
			duration: 1.2,
			delay: i * 0.2,
			ease:'power4',
			onComplete: function(){
				elc.classList.add('elon');
			}
		});
	});
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 100;
	}else{
		tar_offset = 200;
	}
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}


const expand_open = (parent) => {
	parent.classList.add('open');
	const exc = parent.querySelectorAll('.exc');
	gsap.utils.toArray(exc).forEach(ex => {
		gsap.to(ex,{
			height:'auto',
			duration:0.3,
			ease: "power2.inOut",
			onComplete: () => {
				ScrollTrigger.refresh();
			}
		});
	});
}
const expand_close = (parent) => {
	parent.classList.remove('open');
	const exc = parent.querySelectorAll('.exc');
	gsap.utils.toArray(exc).forEach(ex => {
		gsap.to(ex,{
			height: 0,
			duration:0.3,
			ease: "power2.inOut",
			onComplete: () => {
				ScrollTrigger.refresh();
			}
		});
	});
}
const expand_event = (e) => {
	let parent = e.target.closest('.ex');
	const result = parent.classList.contains('open');
	if (result) {
		expand_close(parent);
	}else{
		expand_open(parent);
	}
};
function expandFunc() {
	let exbtn = document.querySelectorAll('.exbtn');
	for (let i = 0; i < exbtn.length; i++) {
		let parent = exbtn[i].closest('.ex');
		expand_close(parent);
		exbtn[i].removeEventListener('click',expand_event);
		exbtn[i].addEventListener('click',expand_event);
	}
}


const kvSlider = async () => {
	await new Promise((resolve, reject) => {
		const swiperEl = document.querySelectorAll('#kvslider');
		swiperEl.forEach((swiperEl) => {
			const swiper = new Swiper(swiperEl, {
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				},
				slidesPerView: 1,
				speed: 800,
				spaceBetween: 0,
				loop: true,
				autoplay: {
					delay: 4000,
				},
				keyboard: {
					enabled: false,
					onlyInViewport: false,
				},
				pagination: {
					el: '#kvpagination',
					type: 'bullets',
					clickable: true,
				},
			});
		});
		resolve();
	});
}

const topaSlider = async () => {
	await new Promise((resolve, reject) => {
		const swiper = new Swiper('#topa_slider', {
			slidesPerView: 1,
			speed: 800,
			spaceBetween: 10,
			loop: true,
			autoplay: {
				delay: 4000,
			},
			keyboard: {
				enabled: false,
				onlyInViewport: false,
			},
			navigation: {
				prevEl: '#topa_prev',
				nextEl: '#topa_next',
			},
			pagination: {
				el: '#topa_page',
				type: 'custom',
				renderCustom: function (swiper, current, total) {
					return current + ' / ' + total;
				}
			},
		});
		resolve();
	});
}

const toplSlider = async () => {
	await new Promise((resolve, reject) => {
		let swiper;
		let swiperbool = false;
		const mediaQueryList = window.matchMedia('(min-width:768px)');
		const listener = (event) => {
			if (event.matches) {
				swiper = new Swiper('#topl_slider', {
					slidesPerView: 'auto',
					// speed: 800,
					// loop: true,
					keyboard: {
						enabled: false,
						onlyInViewport: false,
					},
					navigation: {
						prevEl: '#topl_prev',
						nextEl: '#topl_next',
					},
				});
				swiperbool = true;
			}else{
				if(swiperbool){
					swiper.destroy(false, true);
					swiperbool = false;
				}
			}
		};
		mediaQueryList.addEventListener("change", listener);
		listener(mediaQueryList);

		resolve();
	});
}


let playerArr;
const itemSlider = async () => {
	playerArr=[];
	await new Promise((resolve, reject) => {
		
		const ytplayer = document.querySelectorAll('.ytplayer');
		if(ytplayer.length){
			let ytPlayerFunc;
			ytplayer.forEach(function (ytp) {
				const _videoid = ytp.dataset.videoid;
				const ytpVars = {
					'controls': false,
					'disablekb': 1,
					'loop': 1,
					'playlist': _videoid,
					'modestbranding': 1,
					'rel': 0,
					'playsinline': 1,
				};
				ytPlayerFunc = () => {
					const player = new YT.Player(ytp, {
						videoId: _videoid,
						playerVars: ytpVars,
						events: {
							'onReady': onPlayerReady,
						},
					});
					playerArr.push(player);
				}
				function onPlayerReady(event) {
					// event.target.mute();
					// event.target.playVideo();
				}
			});
			const ytLoadFunc = () => {
				if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
					var tag = document.createElement('script');
					tag.src = "https://www.youtube.com/iframe_api";
					var firstScriptTag = document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);		
				}else{
					ytPlayerFunc();
				}
				window.onYouTubePlayerAPIReady = function() {
					ytPlayerFunc();
				};
			}
			ytLoadFunc();
		}

		const item_slider = document.querySelectorAll('.item_slider');
		item_slider.forEach(function (target, i) {
			const _prev = target.querySelector('.item_prev');
			const _next = target.querySelector('.item_next');
			const $thumb = $(target).parent().find('.thumb');
			const swiper = new Swiper(target, {
				slidesPerView: 1,
				spaceBetween: 10,
				keyboard: {
					enabled: false,
					onlyInViewport: false,
				},
				navigation: {
					prevEl: _prev,
					nextEl: _next,
				},
			});
			swiper.on('slideChange', function () {
				playerArr.forEach(function (player) {
					player.pauseVideo();
				}
			)});
			$thumb.on('click', function(){
				const _index = $(this).attr('data-index');
				swiper.slideTo(_index);
			});
		});
	
		resolve();
	});
}


function works_item() {
	const grid = new Muuri('.grid', {
		dragEnabled: false,
	});
	const sortfilterA = document.querySelectorAll('.fil_check0');
	const sortfilterB = document.querySelectorAll('.fil_check1');
	const activefilter = [];
	function handleCheckboxGroup(group, type) {
		group.forEach(function (checkbox) {
			checkbox.addEventListener('change', function () {
				if (!this.checked) {
					activefilter[type] = null;
				}else{
					activefilter[type] = this.getAttribute('data-filter');
				}
				group.forEach(function (otherCheckbox) {
					if (otherCheckbox !== checkbox) {
						otherCheckbox.checked = false;
					}
				});
				const filteredArr = activefilter.filter(item => item !== null);
				if (filteredArr.length > 0) {
					grid.filter(function (item) {
						var itemTag = item.getElement().getAttribute('data-tag');
						var itemTagArray = itemTag.split(' ');
						return filteredArr.every(function (filter) {
							return itemTagArray.includes(filter);
						});
					});
				} else {
					grid.filter('*');
				}	
			});
		});
	}
	handleCheckboxGroup(sortfilterA, 0);
	handleCheckboxGroup(sortfilterB, 1);
}

const modalSlider = async () => {
	await new Promise((resolve, reject) => {
		const modal_swiper = new Swiper('#modal_slider', {
			slidesPerView: 1,
			speed: 800,
			spaceBetween: 10,
			keyboard: {
				enabled: false,
				onlyInViewport: false,
			},
			navigation: {
				prevEl: '#modal_prev',
				nextEl: '#modal_next',
			},
		});
		$('.item').on('click', function(){
			const _num = $(this).attr('data-num')-1;
			modal_swiper.slideTo(_num, 0)
			$('.modal').addClass('active');
		});
		$('#modalclose').on('click', function(){
			$('.modal').removeClass('active');
		});
	
		resolve();
	});
}


const sdgsSlider = async () => {
	await new Promise((resolve, reject) => {
		const modal_swiper = new Swiper('#sdgs_slider', {
			slidesPerView: 1,
			speed: 800,
			spaceBetween: 10,
			keyboard: {
				enabled: false,
				onlyInViewport: false,
			},
			navigation: {
				prevEl: '#sdgs_prev',
				nextEl: '#sdgs_next',
			},
		});	
		resolve();
	});
}


const item_template = async () => {
	const template = document.getElementById('banners');
	const gnavwrap = document.querySelectorAll('.banners');
	gnavwrap.forEach((target, i) => {
		var clone = template.content.cloneNode(true);
		target.appendChild(clone);
	});	
}

const concept_cssAnime = () => {
	const elem = document.getElementById('concept_kv_img');
	const children = elem.children;
	for (var e of children) {
		e.style.animationName = 'flow';
	}
}

const contactAdd = () => {
	let _num = 2;
	const addlist = document.querySelectorAll('.addlist');
	$('#addbtn').on('click', function(){
		$('#addlist'+_num).addClass('active');
		if(_num == 3){
			$(this).addClass('hide');
		}else{
			_num++;
		}
	});
}
const contactMousewheeel = () => {
	document.querySelectorAll('input[type="number"]').forEach(input => {
    input.addEventListener('wheel', event => event.preventDefault(), { passive: false });
  });
}
const contact_Cf7 = () => {
	console.log('cf7');
	if (wpcf7 && typeof wpcf7.init === 'function') { 
		document.querySelectorAll( '.wpcf7 > form' ).forEach( function( form ) {
			wpcf7.init( form );
		});
	}
}
const contactThanks = () => {
	document.addEventListener( 'wpcf7mailsent', function( event ) {
		barba.go('/thanks/');
	}, false );
}

const mediaQueryList = window.matchMedia('(max-width:767px)');
const listener = (event) => {
	body.removeClass('navopen');
	if (event.matches) {
	}
};
mediaQueryList.addEventListener("change", listener);
listener(mediaQueryList);


var PageEvents = {
	loadFunc: function(){
		winsize();
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		pageScroll();
		elFunc('.el');
		expandFunc();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		kvSlider();
		topaSlider();
		toplSlider();
	},
	worksFunc: function(){
		works_item();
		modalSlider();
	},
	globalFunc: function(){
		topaSlider();
	},
	products_singleFunc: function(){
		itemSlider();
		item_template();
	},
	pm_singleFunc: function(){
		itemSlider();
		item_template();
	},
	sdgsFunc: function(){
		sdgsSlider();
	},
	conceptFunc: function(){
		concept_cssAnime();
	},
	contactFunc: function(){
		contactAdd();
		contactMousewheeel();
		contactThanks();
	},
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceBogo = function(data){
		let target = '#wrapper';
		if(data){
			target = data.next.html;
		}
		const $newPageBogo = $(target).find('.bogo-language-switcher').html();
		body.find('#bogo').html($newPageBogo);
	}
	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false}, ease:'power2', onComplete:function(){
						resolve();
					}});
				}else{
					gsap.to(window, { duration:0, scrollTo:{y:0, autoKill:false}, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0, scrollTo:{y:0, autoKill:false}, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	var $footerelm = $('footer')
	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen single_page');
		document.getElementById('btt').classList.remove('show');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			if( document.getElementById('contact') ){
				contact_Cf7();
			}		
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('works') ){
		PageEvents.worksFunc();
	}
	if( document.getElementById('global') ){
		PageEvents.globalFunc();
	}
	if( document.getElementById('products_single') ){
		PageEvents.products_singleFunc();
	}
	if( document.getElementById('production-method_single') ){
		PageEvents.pm_singleFunc();
	}
	if( document.getElementById('sustainable') ){
		PageEvents.sdgsFunc();
	}
	if( document.getElementById('concept') ){
		PageEvents.conceptFunc();
	}
	if( document.getElementById('contact') ){
		PageEvents.contactFunc();
	}
	
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}